<template>

  <div>

    <validation-observer ref="createEnlistmentValidation">

      <b-form
        class="auth-forgot-password-form mt-2"
        @submit.prevent="createEnlistment"
      >
        <b-row>

          <b-col
            cols="12"
            md="4"
          >
            
            <b-form-group
              label="Número estiba"
              label-for="stowage_id"
            >
              <validation-provider
                  #default="{ errors }"
                  name="Número estiba"
                  rules="required"
                >

                <b-form-select
                  id="stowage_id"
                  v-model="stowageId"
                  :options="stowageNumbersOptions"
                  @change="selectStowage()"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

            </b-form-group>

          </b-col>

        </b-row>


      </b-form>
    </validation-observer>

    <b-card 
    v-if="boxes"
    title="Cajas disponibles"
    sub-title="Recuerda que estas son las cajas disponibles para alistamiento de kits">

    <b-row>
        <b-col
          v-for="(box, index) in boxes" :key="index"
          cols="20"
          lg="1"
          class="text-center"
        >
        <p v-if="box.ready_for_dispatch == 0">#{{ box.box_number }}</p>
        
        </b-col>
      </b-row>

    <b-form
      class="auth-forgot-password-form mt-2"
      @submit.prevent="finishBoxEnlistment"
    >

      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Número de caja"
            label-for="box_by_stowage"
            description="Ingresa el número de la caja que se esta alistando para despacho"
          >
            <validation-provider
                #default="{ errors }"
                name="Número de caja"
                rules="required"
              >

              <b-form-input
                type="number"
                id="box_by_stowage"
                v-model="boxId"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

          </b-form-group>

        </b-col>

      </b-row>

      <!-- Action Buttons -->
      <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        type="submit"
      >
        Guardar
      </b-button>

    </b-form>

  </b-card>

  </div>



</template>

<script>
import {
 BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox, BFormSelect
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import { required, } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {constants} from "@/helpers/constants"

export default {
 components: {
   BButton,
   BMedia,
   BAvatar,
   BRow,
   BCol,
   BFormGroup,
   BFormInput,
   BForm,
   BTable,
   BCard,
   BCardHeader,
   BCardTitle,
   BFormCheckbox,
   BFormSelect,
   vSelect,
   ValidationProvider,
   ValidationObserver,
 },
 data() {
   return {
     host: constants.hostUrl,
     accessToken: localStorage.getItem('accessToken'),
     boxByStowage: null,
     productsByBox: null,
     productTypeId: null,
     required,
     stowages: null,
     stowageNumbersOptions: [
      {
        text: 'Selecciona una opción',
        value: null,
        disabled: true,
      },
     ],
     stowageId: null,
     boxes: null,
     boxId: null,
   }
 },
 props: {
 },
 setup(props) {
   const productTypeOptions = [
       {
         text: 'Selecciona una opción',
         value: null,
         disabled: true,
       },
       {
         text: 'BOLD NEO',
         value: '1',
       },
       {
         text: 'BOLD PLUS',
         value: '2',
       },
       {
         text: 'BOLD SMART',
         value: '3',
       },
        {
          text: 'BOLD PRO',
          value: '4',
        },
     ]

   return {
     productTypeOptions,
   }
 },
 created() {
  this.getStowages()
 },
 methods: {
  getStowages() {
    let headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.accessToken
    }
    axios.get(this.host + '/api/front-web/stowages', { headers }).then(response => {
      console.log('stowages: ', response)

      this.stowages = response.data.data.stowages

      this.stowages.forEach(stowage => {
        if(stowage.finalized == 1 && stowage.available == 1) {
          let item = {
            text: 'Estiba #' + stowage.stowage_number,
            value: stowage.stowage_number,
          }
          this.stowageNumbersOptions.push(item)
        }
        
      });
      
    }).catch( err => {
      console.log(err)
    })
  },
  selectStowage() {
    let headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.accessToken
    }
    axios.get(this.host + '/api/front-web/get-boxes-by-stowage/' + this.stowageId, { headers }).then(response => {
      console.log('get-boxes-by-stowage: ', response)

      this.boxes = response.data.data.boxes
      
    }).catch( err => {
      console.log(err)
    })
  },

  finishBoxEnlistment() {

    event.preventDefault()
    this.$refs.createEnlistmentValidation.validate().then(success => {
      if (success) {

        let headers = {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.accessToken
        }
        axios.post(this.host + '/api/front-web/finish-box-enlistment',{
          box_id: this.boxId,
        }, { headers }).then(response => {
          console.log('finish-box-enlistment: ', response)

          if(response.data.status) {
            this.$toast({
              component: ToastificationContentVue,
              position: 'top-right',
              props: {
                title: `OK`,
                icon: 'CheckCircleIcon',
                variant: 'success',
                text: response.data.message,
              },
            })

            this.selectStowage()

          } else {
            this.$toast({
              component: ToastificationContentVue,
              position: 'top-right',
              props: {
                title: `Advertencia`,
                icon: 'XCircleIcon',
                variant: 'danger',
                text: response.data.message,
              },
            })
          }
        }).catch( err => {
          console.log(err)
        })

      }
    })

  },
  createEnlistment() {
    event.preventDefault()
    this.$refs.createEnlistmentValidation.validate().then(success => {
      if (success) {

        let headers = {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.accessToken
        }
        axios.post(this.host + '/api/front-web/create-enlistment',{
          product_type_id: this.productTypeId,
          products_by_box: this.productsByBox,
          boxes_by_stowage: this.boxByStowage,
        }, { headers }).then(response => {
          console.log('create-enlistment: ', response)

          if(response.data.status) {
            this.$toast({
              component: ToastificationContentVue,
              position: 'top-right',
              props: {
                title: `OK`,
                icon: 'CheckCircleIcon',
                variant: 'success',
                text: response.data.message,
              },
            })

            let stowage = response.data.data.stowage
            this.$router.push({ name: 'read-product-early-enlistment', params: { stowage_id:stowage.id } })

          } else {
            this.$toast({
              component: ToastificationContentVue,
              position: 'top-right',
              props: {
                title: `Advertencia`,
                icon: 'XCircleIcon',
                variant: 'danger',
                text: response.data.message,
              },
            })
          }
        }).catch( err => {
          console.log(err)
        })

      }
    })


  }
 },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
