<template>

  <b-card 
    title="Alistamiento"
    sub-title="Alistamiento de kits">

    <b-row>
      <b-col cols="12">

        <enlistment-dispatch-card/>

      </b-col>
    </b-row>

  </b-card>
  
</template>

<script>
import router from '@/router'
import { BRow, BCol, BTabs, BTab, BCard } from 'bootstrap-vue'
import EnlistmentDispatchCard from './EnlistmentDispatchCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    BCard,

    EnlistmentDispatchCard,
  },
  data() {
    return {
    }
  },
  created() {
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>